import revive_payload_client_Hjmf4WeR2N from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ySWanUoDla from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Or2oaplyd8 from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_RZzzgjCV7E from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_rpN6vpLoWw from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iIdmaAQMx1 from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_I8AhPB5cLY from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_Mv6JmDMR9v from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/.nuxt/components.plugin.mjs";
import prefetch_client_GGXNTOA1qA from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_client_jsii62HQyK from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_client_UW5GhnqGCv from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import titles_wqOibtcYRx from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_uttfrzXIZR from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_oicdFpUZIX from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_10ErEN0y9X from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_9b0Y3yuF00 from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_FY4uBIllea from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import market_jow2NjF82a from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/packages/locale/src/runtime/plugins/market.ts";
import vue_fontawesome_NAPA5ifGXX from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/packages/icons/plugins/vue-fontawesome.ts";
import composition_R0egfK6XcH from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_f1SKBejfl8 from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import floating_vue_EIcJ7xiw0h from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/.nuxt/floating-vue.mjs";
import plugin_4J4UPyNRvH from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/packages/persiststate/src/runtime/plugin.ts";
import directives_client_g4DTYnPn0a from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/base/plugins/directives.client.js";
import mitt_client_TW5tO9qcAQ from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/base/plugins/mitt.client.js";
import _0_sentry_grz12wCwjn from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/plugins/0.sentry.ts";
import chunk_error_reload_client_gEQ9cucyzX from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/plugins/chunk-error-reload.client.ts";
import crispchat_client_pJ0y4pPthy from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/plugins/crispchat.client.js";
import currency_BGheaMuyfO from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/plugins/currency.ts";
import facebook_client_qdkGHno3mD from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/plugins/facebook.client.js";
import fontawesome_3AZAhAAeTA from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/plugins/fontawesome.ts";
import gtm_iframe_client_vRu3WQY33l from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/plugins/gtm-iframe.client.ts";
import hotjar_client_UrNcjNPjBY from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/plugins/hotjar.client.js";
import maska_pN1uokkAIh from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/plugins/maska.ts";
import number_format_upzEP71alS from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/plugins/number-format.js";
import process_UNTZBJAHqg from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/plugins/process.ts";
import profit_metrics_client_Yfq7SkZGZP from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/plugins/profit-metrics.client.js";
import sentry_non_json_api_response_CYHjUrtPOS from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/plugins/sentry-non-json-api-response.js";
import temp_remove_weather_cache_client_f7Oi9aKCXR from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/plugins/temp-remove-weather-cache.client.ts";
import tiktok_client_uGkNQeIBBL from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/plugins/tiktok.client.js";
import jwt_expire_logout_client_WFMg5gFcYK from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/plugins/jwt-expire-logout.client.js";
import single_finder_enable_by_param_client_sY0sBjvJr9 from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/plugins/single-finder-enable-by-param.client.ts";
export default [
  revive_payload_client_Hjmf4WeR2N,
  unhead_ySWanUoDla,
  router_Or2oaplyd8,
  _0_siteConfig_RZzzgjCV7E,
  payload_client_rpN6vpLoWw,
  navigation_repaint_client_iIdmaAQMx1,
  check_outdated_build_client_I8AhPB5cLY,
  plugin_vue3_Mv6JmDMR9v,
  components_plugin_KR1HBZs4kY,
  prefetch_client_GGXNTOA1qA,
  pwa_client_jsii62HQyK,
  plugin_client_UW5GhnqGCv,
  titles_wqOibtcYRx,
  defaultsWaitI18n_uttfrzXIZR,
  siteConfig_oicdFpUZIX,
  inferSeoMetaPlugin_10ErEN0y9X,
  plugin_9b0Y3yuF00,
  plugin_FY4uBIllea,
  market_jow2NjF82a,
  vue_fontawesome_NAPA5ifGXX,
  composition_R0egfK6XcH,
  i18n_f1SKBejfl8,
  floating_vue_EIcJ7xiw0h,
  plugin_4J4UPyNRvH,
  directives_client_g4DTYnPn0a,
  mitt_client_TW5tO9qcAQ,
  _0_sentry_grz12wCwjn,
  chunk_error_reload_client_gEQ9cucyzX,
  crispchat_client_pJ0y4pPthy,
  currency_BGheaMuyfO,
  facebook_client_qdkGHno3mD,
  fontawesome_3AZAhAAeTA,
  gtm_iframe_client_vRu3WQY33l,
  hotjar_client_UrNcjNPjBY,
  maska_pN1uokkAIh,
  number_format_upzEP71alS,
  process_UNTZBJAHqg,
  profit_metrics_client_Yfq7SkZGZP,
  sentry_non_json_api_response_CYHjUrtPOS,
  temp_remove_weather_cache_client_f7Oi9aKCXR,
  tiktok_client_uGkNQeIBBL,
  jwt_expire_logout_client_WFMg5gFcYK,
  single_finder_enable_by_param_client_sY0sBjvJr9
]