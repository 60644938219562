import { default as _91slug_93UnL1YOQbIfMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue?macro=true";
import { default as _91slug_93iEEBFhn2VcMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue?macro=true";
import { default as _91name_93FiGiyDjfzbMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue?macro=true";
import { default as _91paxid_93tpW6r6HsRCMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/satisfactionstatus/[triptypeid]/[paxid].vue?macro=true";
import { default as _91id_93OedlNqT1GlMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/payment/[id].vue?macro=true";
import { default as _91id_93iz81rSaM1GMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/omdome/[id].vue?macro=true";
import { default as _91tab_939cktA7RAoTMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue?macro=true";
import { default as _91tab_93D4Satx8w8lMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue?macro=true";
import { default as _91tab_939SS0aeweacMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue?macro=true";
import { default as _91tab_93T23bEHE0emMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue?macro=true";
import { default as _91slug_93OcHCby4vZkMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/info/[slug].vue?macro=true";
import { default as _91id_932PAaUmvgnrMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/fn/[id].vue?macro=true";
import { default as _91tab_93CpHX7cqde6Meta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue?macro=true";
import { default as _91slug_93eHfsHbhrsTMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/competition/[slug].vue?macro=true";
import { default as _91slug_93dIbjtZXbvcMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue?macro=true";
import { default as _91ocr_932GoyS5il8fMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue?macro=true";
import { default as _91ocr_93Auz3TSP9cnMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue?macro=true";
import { default as _91id_935Vab4bqEcVMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue?macro=true";
import { default as _91tripid_93acB1cOiItsMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/bookflight/[tripid].vue?macro=true";
import { default as _91step_93z3ND1FUUqnMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue?macro=true";
import { default as _91slug_93F5A2VMAEbSMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue?macro=true";
import { default as _91jwt_93lVJijoYgYZMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue?macro=true";
import { default as _91tab_93q2l2bi9bYGMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue?macro=true";
import { default as indexDv5SZlUQjdMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/app/index.vue?macro=true";
import { default as callbackYbHuHbljKfMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/callback.vue?macro=true";
import { default as blackweekI1CDJ242n4Meta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/blackweek.vue?macro=true";
import { default as _91slug_93YqLtUn9SywMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/[slug].vue?macro=true";
import { default as indexKrYOVq8Vk5Meta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/index.vue?macro=true";
import { default as bookcalle6K93LHT2CMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/bookcall.vue?macro=true";
import { default as byebyeiePAoAicToMCMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/byebyeie.vue?macro=true";
import { default as indexOdS4lXCHPKMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/index.vue?macro=true";
import { default as lastminute3gmx2qV8GGMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/lastminute.vue?macro=true";
import { default as _91name_93a6TWjLzWFyMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/[name].vue?macro=true";
import { default as indexh9qHfmm0EOMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/index.vue?macro=true";
import { default as catalogueBP83CSJLNvMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/catalogue.vue?macro=true";
import { default as complaintQygH5l8PscMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/complaint.vue?macro=true";
import { default as dinbokning_45aktuell6UTt19ehxJMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue?macro=true";
import { default as _91slug_93mGNz7ELmkRMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/[slug].vue?macro=true";
import { default as indexiPOSrovAkzMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/index.vue?macro=true";
import { default as faqn0oMPc0WE4Meta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/faq.vue?macro=true";
import { default as _91id_933MH0lKpRPUMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue?macro=true";
import { default as indexQp1uNuCyteMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/giftcard/index.vue?macro=true";
import { default as _91name_937YFvEV0e5oMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/hotels/[name].vue?macro=true";
import { default as indexmEb6cbo65jMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/hotels/index.vue?macro=true";
import { default as indexUimFd559pYMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/index.vue?macro=true";
import { default as grouptravelIhx00xre5OMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/grouptravel.vue?macro=true";
import { default as index90Ze8KPNwwMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/index.vue?macro=true";
import { default as lastminuteUVaud3cg91Meta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/lastminute.vue?macro=true";
import { default as mapIuEVEZWo36Meta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/map.vue?macro=true";
import { default as dinbokning2FltSJZ22zMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue?macro=true";
import { default as newsletteryAAfgANd26Meta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/newsletter.vue?macro=true";
import { default as offlineQvEDgDmzE3Meta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/offline.vue?macro=true";
import { default as failedzEXBm5L3WfMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/payment/failed.vue?macro=true";
import { default as index2JX8pWdG9MMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/review/index.vue?macro=true";
import { default as searchkA4YoY50aKMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/search.vue?macro=true";
import { default as index79hX5XfGYuMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/tourleader/index.vue?macro=true";
import { default as verification_45donevrHySWbDDIMeta } from "/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/verification-done.vue?macro=true";
export default [
  {
    name: "tourleader-id",
    path: "/reseledare/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "titinerary",
    path: "/dagprogram",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "shipinfo",
    path: "/fartyg",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "satisfactionstatus-triptypeid",
    path: "/nojdhet-status/:triptypeid()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/satisfactionstatus/[triptypeid]/[paxid].vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "omdome",
    path: "/omdome",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "mytrip",
    path: "/dinresa",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "info-lectures",
    path: "/info/forelasningar",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue")
  },
  {
    name: "info-connections",
    path: "/info/anslutning",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue")
  },
  {
    name: "info-about",
    path: "/info/om",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "fn",
    path: "/fn",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "destination-slug",
    path: "/destination/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue")
  },
  {
    name: "competition",
    path: "/tavling",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "category",
    path: "/kategori",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue")
  },
  {
    name: "bookingfaq",
    path: "/boknings-faq",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "booking-finish",
    path: "/booking-finish",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "booking-confirmation",
    path: "/booking-confirmation",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "bookflight",
    path: "/boka-flyg",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/bookflight/[tripid].vue")
  },
  {
    name: "book-tripid",
    path: "/boka/:tripid()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "auth",
    path: "/medlem",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue")
  },
  {
    name: "app-l",
    path: "/app/l",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "triptype-slug",
    path: "/:triptype()/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "triptype-slug-tab",
    path: "/:triptype()/:slug()/:tab()",
    meta: _91tab_93q2l2bi9bYGMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/app/index.vue")
  },
  {
    name: "app-l-jwt",
    path: "/app/l/:jwt()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "auth-slug",
    path: "/medlem/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue")
  },
  {
    name: "auth-callback",
    path: "/medlem/callback",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/callback.vue")
  },
  {
    name: "blackweek",
    path: "/blackweek",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/blackweek.vue")
  },
  {
    name: "blog-slug",
    path: "/blogg/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blogg",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/index.vue")
  },
  {
    name: "book-tripid-step",
    path: "/boka/:tripid()/:step()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "bookcall",
    path: "/boka-samtal",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/bookcall.vue")
  },
  {
    name: "bookflight-tripid",
    path: "/boka-flyg/:tripid()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/bookflight/[tripid].vue")
  },
  {
    name: "booking-confirmation-id",
    path: "/booking-confirmation/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "booking-finish-ocr",
    path: "/booking-finish/:ocr()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "bookingfaq-ocr",
    path: "/boknings-faq/:ocr()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "byebyeie",
    path: "/byebyeie",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/byebyeie.vue")
  },
  {
    name: "calendar",
    path: "/kalender",
    meta: indexOdS4lXCHPKMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/index.vue")
  },
  {
    name: "calendar-lastminute",
    path: "/kalender/sista-minuten",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/lastminute.vue")
  },
  {
    name: "campaign-name",
    path: "/kampanj/:name()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/[name].vue")
  },
  {
    name: "campaign",
    path: "/kampanj",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/index.vue")
  },
  {
    name: "catalogue",
    path: "/katalog",
    meta: catalogueBP83CSJLNvMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/catalogue.vue")
  },
  {
    name: "category-slug",
    path: "/kategori/:slug()",
    meta: _91slug_93dIbjtZXbvcMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue")
  },
  {
    name: "competition-slug",
    path: "/tavling/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "complaint",
    path: "/reklamation",
    meta: complaintQygH5l8PscMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/complaint.vue")
  },
  {
    name: "destination-slug-tab",
    path: "/destination/:slug()/:tab()",
    meta: _91tab_93CpHX7cqde6Meta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue")
  },
  {
    name: "dinbokning-aktuell",
    path: "/dinbokning-aktuell",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue")
  },
  {
    name: "event-slug",
    path: "/event/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/[slug].vue")
  },
  {
    name: "event",
    path: "/event",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/faq.vue")
  },
  {
    name: "fn-id",
    path: "/fn/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "giftcard-id",
    path: "/presentkort/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue")
  },
  {
    name: "giftcard",
    path: "/presentkort",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/giftcard/index.vue")
  },
  {
    name: "hotels-name",
    path: "/hotell/:name()",
    meta: _91name_937YFvEV0e5oMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/hotels/[name].vue")
  },
  {
    name: "hotels",
    path: "/hotell",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/hotels/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/index.vue")
  },
  {
    name: "info-slug",
    path: "/info/:slug()",
    meta: _91slug_93OcHCby4vZkMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "info-about-tab",
    path: "/info/om/:tab()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue")
  },
  {
    name: "info-connections-tab",
    path: "/info/anslutning/:tab()",
    meta: _91tab_939SS0aeweacMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue")
  },
  {
    name: "info-grouptravel",
    path: "/info/gruppresor",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/grouptravel.vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/index.vue")
  },
  {
    name: "info-lectures-tab",
    path: "/info/forelasningar/:tab()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue")
  },
  {
    name: "lastminute",
    path: "/sista-minuten-erbjudanden",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/lastminute.vue")
  },
  {
    name: "map",
    path: "/karta",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/map.vue")
  },
  {
    name: "mytrip-tab",
    path: "/dinresa/:tab()",
    meta: _91tab_939cktA7RAoTMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "mytrip-dinbokning",
    path: "/mytrip/dinbokning",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue")
  },
  {
    name: "newsletter",
    path: "/nyhetsbrev",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/newsletter.vue")
  },
  {
    name: "offline",
    path: "/offline",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/offline.vue")
  },
  {
    name: "omdome-id",
    path: "/omdome/:id()",
    meta: _91id_93iz81rSaM1GMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "payment-id",
    path: "/payment/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "payment-failed",
    path: "/payment/failed",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/payment/failed.vue")
  },
  {
    name: "review",
    path: "/utvardera-resa",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/review/index.vue")
  },
  {
    name: "satisfactionstatus-triptypeid-paxid",
    path: "/nojdhet-status/:triptypeid()/:paxid()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/applications/rolfsbuss-web/pages/satisfactionstatus/[triptypeid]/[paxid].vue")
  },
  {
    name: "search",
    path: "/sok",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/search.vue")
  },
  {
    name: "shipinfo-name",
    path: "/fartyg/:name()",
    meta: _91name_93FiGiyDjfzbMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "titinerary-slug",
    path: "/dagprogram/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "tourleader-id-slug",
    path: "/reseledare/:id()/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "tourleader",
    path: "/reseledare",
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/tourleader/index.vue")
  },
  {
    name: "verification-done",
    path: "/verification-done",
    meta: verification_45donevrHySWbDDIMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-11-1208/rtg-monorepo/src/layers/web/pages/verification-done.vue")
  }
]